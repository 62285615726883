<template>
  <div style="background-color: #f2f2f3">
    <van-nav-bar title="记录明细" left-arrow @click-left="onClickLeft" />
    <van-tabs
      v-model="active"
      title-active-color="#FBC034"
      title-inactive-color="#333333"
      ref="tabs"
    >
      <van-tab
        title="收入明细"
        to="/recordDetails/incomeBreakdown?type=0"
      ></van-tab>
      <van-tab
        title="提现记录"
        to="/recordDetails/withdrawalsRecord?type=1"
      ></van-tab>
    </van-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: Number(this.$route.query.type)
    };
  },
  created() {
    this.$nextTick(() => {
      this.$refs.tabs.resize();
    });
  },
  methods: {
    onClickLeft() {
      this.$router.push("/withdrawal");
    }
  },
  mounted() {
    console.log(this.$route.query.type);
  }
};
</script>

<style lang="scss" scoped>
/deep/body {
  background-color: #f2f2f3 !important;
  height: 200% !important;
}
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
/deep/ .van-tabs__line {
  background-color: #fbc034;
  width: 50%;
  height: 4px;
}
</style>
